.my_nav {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
}

.main_nav_text {
  font-family: nutella;
  font-size: 64px;
  /* margin-left: 12px; */
  line-height: normal;
  display: flex;
}

.main_nav_text span {
  margin-right: 22px;
}
.logo_block {
  margin-right: 20px;
  height: fit-content;
}

.logo_block img {
  width: 260px;
}

.home-page {
  height: 100%;
  zoom: 90%;
}

.line_bg {
  margin-left: 15px;
  width: inherit;
  height: 80px;
  background-image: url(../../assets/your-text-line.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.bubble_bg {
  width: 100%;
  background-image: url(../../assets/bubble.png);
  background-size: 550px;
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: center;
  margin-top: -50px;
  padding: 90px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}

.big_block {
  display: flex;
  align-items: flex-end;
  width: fit-content;
  height: 500px;
  justify-content: flex-end;
  z-index: 99999999 !important;
  cursor: pointer;
}

.bigArrow {
  position: absolute;
  left: -61px;
  top: 50%;
  z-index: 10;
  width: 100px !important;
}
.bigShadow {
  position: absolute;
  width: 1028px;
  left: -46%;
  top: 88%;
  z-index: 1;
}

.smallArrow {
  position: absolute;
  top: 45%;
  right: -25%;
  z-index: 10;
}

.smallShadow {
  position: absolute;
  width: 1008px;
  left: -62%;
  top: 76%;
}

.text_gr_big {
  font-family: nutella;
  color: #fff;
  position: absolute;
  left: 60%;
  top: 109%;
  transform: translate(-50%, -50%);
  z-index: 9999999999999;
  width: -moz-fit-content;
  width: fit-content;
}
.text_gr_small {
  font-family: nutella;
  color: #fff;
  position: absolute;
  left: 34%;
  top: 108.5%;
  transform: translate(-50%, -50%);
  z-index: 9999999999999;
  width: -moz-fit-content;
  width: fit-content;
}

.click_here_text {
  font-family: nutella;
  font-size: 22px;
  position: absolute;
  width: fit-content;
  top: 52%;
}
.small_block {
  display: flex;
  align-items: flex-end;
  width: fit-content;
  height: 500px;
  cursor: pointer;
}

.big_block_shadow {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 62.5%;
  top: 97%;
  transform: translate(-50%, -50%);
}

.small_block_shadow {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 39%;
  top: 97.5%;
  transform: translate(-50%, -50%);
}

.row_item {
  margin-top: -76px;
}

.big_block_col {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.small_block_col {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.smallNutellaMobile,
.bigNutellaMobile {
  display: none;
}

.main_nav_mobile_text {
  display: none;
}

@media screen and (max-width: 1100px) {
  .main_nav_text {
    flex-direction: column;
  }
  .line_bg {
    width: 175px;
  }
  .yours_text_block {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
  .main_nav_mobile_text {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .main_nav_text {
    display: none;
  }
  .my_nav {
    /* margin: 0px !important; */
  }
  .bubble_bg {
    margin-top: 10px;
  }
}
@media screen and (max-width: 990px) {
  .row_item {
  }
  .big_block_shadow {
    left: 467px;
  }
  .text_gr_big {
    color: #000;
  }
  .text_gr_small {
    color: #000;
  }
  .big_block {
    /*margin-top: 90px;*/
    height: auto;
  }
  .big_block a {
    /*margin-top: 90px;*/
    width: auto !important;
    height: 100%;
  }
  .big_block_col {
    justify-content: center;
  }
  .small_block_col {
    justify-content: center;
  }
  .small_block {
    margin-top: 50px;
    height: auto;
  }
  .small_block a {
    /*margin-top: 90px;*/
    width: auto !important;
    height: 100%;
  }
  .small_block_shadow {
    left: 509px;
  }
  .smallNutella,
  .bigNutella,
  .text_gr_small,
  .big_block_shadow,
  .text_gr_big,
  .small_block_shadow {
    display: none;
  }

  .smallNutellaMobile,
  .bigNutellaMobile {
    display: inline-block;
  }

  .bigNutellaMobile {
    margin-left: 12%;
  }

  .smallNutellaMobile {
    margin-left: 23%;
  }

  .app {
    overflow: auto;
    padding-bottom: 20px;
    background-size: auto;
    background-image: url("../../assets/red_bg.png");
    background-repeat: no-repeat;
    background-position: bottom;
    height: 100vh;
  }
  .row_item {
    display: block;
  }

  .big_block_col {
    display: block;
  }

  .home-page {
    height: auto;
    zoom: 100%;
  }

  /* .main_nav_text{
    display: inline-block;
    margin-top: 20px;
  } */
  .my_nav {
    margin-top: 0px !important;
  }

  .bubble_bg {
    background-size: 464px;
  }
}
@media screen and (max-width: 900px) {
  .main_nav_text {
    font-size: 50px;
  }
  .logo_block img {
    width: 230px;
  }
  .line_bg {
    width: 144px;
  }
}
@media screen and (max-width: 760px) {
  .main_nav_text span {
    margin-right: 12px;
  }
  .main_nav_text {
    font-size: 36px;
  }
  .logo_block img {
    width: 138px;
    padding-top: 9px;
  }
  .line_bg {
    width: 144px;
  }
}
@media screen and (max-width: 640px) {
  .bubble_bg {
    background-size: 385px;
  }
  .smallNutellaMobile {
    margin-left: 22%;
  }
}
@media screen and (max-width: 480px) {
  .main_nav_text span {
    margin-right: 5px;
  }

  .main_nav_text {
    font-size: 22px;
  }

  .logo_block img {
    width: 110px;
    padding-top: 9px;
  }

  .line_bg {
    width: 70px;
    margin: 0;
    margin-top: 5px;
  }
  .bubble_bg {
    background-size: 300px;
  }

  .smallNutellaMobile {
    margin-left: 21%;
  }
}
