.selected_container {
  background-image: url("../../assets/red_bg_rotate.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: top;
}

.selected_img {
  width: 500px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 30px;
}

.nutella_input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.nutella_input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

.nutella_input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.nutella_input:focus::placeholder {
  color: transparent;
}

.nutella_input {
  background-color: #ed1d26;
  width: 100%;
  color: #fff;
  font-family: nutella;
  font-size: 20px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 5px 0;
  border: none;
}

.nutella_input_submit {
  background-color: #ed1d26;
  width: 100%;
  color: #fff;
  font-family: nutella;
  font-size: 20px;
  margin-bottom: 30px;
  line-height: normal;
  border-radius: 11px;
  padding: 10px 0;
  border: none;
}

@media screen and (max-width: 1024px) {
  .nutella_input_submit {
    background-color: #ed1d26;
    width: 100%;
    color: #fff;
    font-family: nutella;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 10px;
    padding-top: 10px;
    border: none;
  }
}

@media screen and (max-width: 770px) {
  .selected_img {
    width: 98%;
    margin-left: 10px;
  }
}

/* print */

.page {
  display: none;
}

@media print {
  html,
  body {
    border: 1px solid white;
    height: 99% !important;
    page-break-after: avoid;
    page-break-before: avoid;
  }

  .sticker {
    margin: 5px 0px;
  }
  .page {
    display: block;
    /* width: 29.7cm;
        height: 20cm !important; */
    padding-top: 50px;
    overflow: hidden;
  }
  .page.landscape {
    display: flex;
    /* width: 29.7cm;
        height: 20cm !important; */
    padding-top: 50px;
    overflow: hidden;
  }
  .form-name {
    display: none;
  }
}
