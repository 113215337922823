@import "bootstrap/dist/css/bootstrap.min.css";


html,
body {
  margin: 0;

}

@font-face {
  font-family: 'ferraro';
  src: url('./fonts/FerreroStrettissimoGentile.otf');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'nutella';
  src: url('./fonts/Nutella.otf');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}




.app {
  overflow: hidden;
  padding-bottom: 140px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('./assets/red_bg.png');
  background-position: bottom;
  height: 100vh;
  
}

