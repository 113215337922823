.print_page_text {
  font-family: nutella;
  font-weight: bold;
}

.name_text {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
}

.sticker {
  margin-bottom: 25px;
  position: relative;
}

.first_letter {
  color: black;
}

.other_latters {
  color: #ed1d26;
}

/* @media (orientation: landscape) {
  .nutella-print {
    width: 11cm !important;
    height: 3.7cm !important;
    display: block;
    margin: 2px auto;
  }
}

@media (orientation: portrait) {
  .nutella-print {
    width: 16.8cm !important;
    height: 6.2cm !important;
    display: block;
    margin: 2px auto;
  }
} */

.portrait .nutella-print {
  width: 16.8cm !important;
  height: 6.2cm !important;
  display: block;
  margin: 2px auto;
}

.landscape .nutella-print {
  width: 13.6cm !important;
  height: 5cm !important;
  display: block;
  margin: 2px auto;
}
